<template>
  <a-form
    ref="changePasswordForm"
    :model="changePasswordForm"
    :rules="resetPasswordRules"
    class="columns is-multiline"
  >
    <a-form-item class="column is-12" :label="$t('Old Password')" name="oldPassword">
      <a-input-password
        v-model:value.trim="changePasswordForm.oldPassword"
        :size="formSize"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Password')" name="password">
      <a-input-password
        v-model:value.trim="changePasswordForm.password"
        :size="formSize"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Retype Password')" name="retypePassword">
      <a-input-password
        v-model:value.trim="changePasswordForm.retypePassword"
        :size="formSize"
      />
    </a-form-item>
    <div class="column is-12 m-t-4x">
      <a-button
        :loading="isSubmitting"
        :size="formSize"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
    <div v-if="message" class="column is-12 m-t-1x">
      <a-alert v-bind="message" />
    </div>
  </a-form>
</template>

<i18n>
{
"en":{
"success": "Your password has been reset successfully.",
"Old Password":"Old Password",
"Password":"Password",
"Retype Password":"Retype Password",
"password-min":"Password must be 6 characters or more.",
"password-required":"Password is required.",
"old-password-min":"Old Password must be 6 characters or more.",
"old-password-required":"Old Password is required.",
"retype-password-required":"Retype Password is required.",
"password-not-match": "Password does not match.",
"Save": "Save"
},
"ja":{
"success": "Your password has been reset successfully.",
"Old Password":"古いパスワード",
"Password":"パスワード",
"Retype Password":"パスワードを再入力",
"password-min":"パスワードは6桁以上である必要があります",
"password-required":"パスワードは必須です",
"old-password-min":"古いパスワードは6桁以上である必要があります",
"old-password-required":"古いパスワードは必須です",
"retype-password-required":"パスワードの再入力は必須です",
"password-not-match": "パスワードがマッチしません",
"Save": "保存"
}
}
</i18n>

<script>
import { mapActions } from 'vuex';
import { getResponseError } from '@/utils/util';

export default {
  name: 'ChangePasswordForm',
  data() {
    return {
      changePasswordForm: {
        oldPassword: '',
        password: '',
        retypePassword: '',
      },
      resetPasswordRules: {
        oldPassword: [
          {
            required: true,
            message: this.$t('old-password-required'),
            trigger: ['change', 'blur'],
          },
          {
            min: 6,
            message: this.$t('old-password-min'),
            trigger: ['change', 'blur'],
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('password-required'),
            trigger: ['change', 'blur'],
          },
          {
            min: 6,
            message: this.$t('password-min'),
            trigger: ['change', 'blur'],
          },
        ],
        retypePassword: [
          {
            type: 'string',
            required: true,
            message: this.$t('retype-password-required'),
            trigger: ['change', 'blur'],
          },
          {
            type: 'string',
            min: 6,
            message: this.$t('password-min'),
            trigger: ['change', 'blur'],
          },
          {
            type: 'string',
            validator: async (rule, value) => {
              if (value !== this.changePasswordForm.password) {
                return Promise.reject(this.$t('password-not-match'));
              }
              return Promise.resolve();
            },
            trigger: ['change', 'blur'],
          },
        ],
      },
      formSize: 'large',
      isSubmitting: false,
      message: undefined,
    };
  },
  methods: {
    ...mapActions('user', ['changePassword']),
    async handleSubmit() {
      try {
        await this.$refs.changePasswordForm.validate();
        this.message = undefined;
        this.isSubmitting = true;
        const payload = {
          oldPassword: this.changePasswordForm.oldPassword,
          newPassword: this.changePasswordForm.password,
        };
        await this.changePassword(payload);
        this.message = {
          message: this.$t('success'),
          type: 'success',
        };
      } catch (err) {
        const message = getResponseError(err);
        if (message) {
          this.message = {
            message,
            type: 'error',
          };
        }
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
