<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container is-max">
          <h2 class="has-text-centered is-3 title m-b">
            {{ $t('title') }}
          </h2>
          <div class="is-size-7 text-center">
            {{ $t('desc', { email: userEmail }) }}
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mini">
        <ChangePasswordForm
          ref="changePasswordForm"
        />
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "title": "Reset password",
    "desc": "Enter your new password for {email}"
  },
  "ja": {
    "title": "リセットパスワード",
    "desc": "{email}の新しいパスワードを入力してください"
  }
}
</i18n>

<script>
import { mapGetters } from 'vuex';
import ChangePasswordForm from '@/views/user/components/ChangePasswordForm';

export default {
  name: 'ChangePassword',
  components: { ChangePasswordForm },
  computed: {
    ...mapGetters({
      userEmail: 'userEmail',
    }),
  },
};
</script>
